import {
  useState,
  useEffect,
  useContext,
} from 'react';

import Context from '../context/context';

import config from '../config.js';

import InputOtp from './InputOtp';

import { TextField } from '@material-ui/core';

import Live from './Live';
import Language from './Language';
import Installation from "./Installation";
import GuideEntrance from "./GuideEntrance";

const backgroundColor1 = 'rgba(95, 91, 237, 1)';
const backgroundColor2 = 'rgba(154, 83, 236, 1)';

export default function Entrance(props) {
  const context = useContext(Context);
  const { addAppState } = props;

  window.gtag('config', 'G-PHLBPVWMT8', {'page_title': 'Entrance'});

  const [state, setState] = useState({
    code1: '',
    code2: '',
    code3: '',
    codePassed: false,
    backgroundColor: backgroundColor1,
    playerInit: false,
    loading: false,
  });

  const addState = (_obj) => {
    setState({
      ...state,
      ..._obj
    });
  }

  useEffect(()=> {
    window.scrollTo(0, 0);
  },[state.codePassed]);

  if (state.codePassed) {
    return (
      <>
        <Live videoUrl={state.videoUrl} nickname={state.nickname} playerInit={state.playerInit}
          addState={addState}
        />
        <GuideEntrance />
        <div className="container"><Installation/></div>
      </>
    );
  } else {
    return (
      <>
        <div className="container">
          <Language />
          <div className="Entrance" style={{backgroundColor: state.backgroundColor}}>
            <div className="Entrance__title1">
              UNI-KON
            </div>
            <div className="Entrance__title2">
              UNIVERSE <span>K-POP</span> CONCERT
            </div>
            <InputOtp code1={state.code1} code2={state.code2} code3={state.code3}
              addState={addState} addAppState={addAppState}/>
            {
              process.env.REACT_APP_ENV === 'qa' && (
              <>
              <div onClick={()=>{context.changeContext({forceStage: 0})}}>
                <div
                  style={{
                    position: "absolute",
                    left: "20px",
                    top: "20px",
                    color: "white",
                    fontSize: "20px",
                    cursor: 'pointer'
                  }}
                >
                  처음으로
                </div>
              </div>
              </>)
            }
          </div>
          {
            (window.navigator.userAgent.indexOf('MSIE') > 0 ||
              window.navigator.userAgent.indexOf('Trident/') > 0 ||
              window.navigator.userAgent.indexOf("Edge/") > -1
            ) ?
              (<div className="avoidIE">
                <div className="avoidIE__message">
                {config.dict[context.language].avoidIE[0]}<br/>
                {config.dict[context.language].avoidIE[1]}
                </div>
              </div>) : null
          }
          <div className="loading" style={state.loading ? {zIndex: 10} : {} }>
            <div className="spinner" style={{background: 'url(/img/loading.png) 50% 50% no-repeat'}}></div>
          </div>
        </div>
        <GuideEntrance />
        <div className="container"><Installation/></div>
      </>
    );
  }
  
}