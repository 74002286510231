import {
  useState,
  useEffect,
  useContext,
} from 'react';

import Context from '../context/context';

import config from '../config.js';

import Language from './Language';
import Installation from "./Installation";
import GuideEntrance from "./GuideEntrance";

const backgroundColor1 = 'rgba(95, 91, 237, 1)';

export default function Youtube(props) {
  const context = useContext(Context);

  window.gtag('config', 'G-PHLBPVWMT8', {'page_title': 'Youtube'});

  if (window.multiPlayer) {
    window.multiPlayer.close();
    delete window.multiPlayer;
  }

  const iframeSrc = context.youtubeUrl;

  useEffect(()=> {
    window.scrollTo(0, 0);
  },[]);

  return (
    <>
      <>
        <div className="Youtube">
          <div className="Youtube__title">
            <span>UNI-KON</span> UNIVERSE K-POP CONCERT
          </div>
          <div className="Youtube__playerContainer">
            <div className="videoWrapper">
              <iframe id="player" type="text/html" src={iframeSrc} frameBorder="0"></iframe>
            </div>
          </div>
        </div>
        <div className="container">
          <Language />
          <Installation/>
        </div>
      </>
    </>
  );
  
}