import { useContext, useEffect } from "react";
import Context from '../context/context';

import {
    HashRouter,
    Router,
    Route,
    Switch,
    Redirect,
    withRouter,
    Link,
} from "react-router-dom";

import Language from './Language';
import Installation from "./Installation";
import Guide from "./GuideEntrance";

import config from '../config.js';


export default function Closing() {
  const context = useContext(Context);

  window.gtag('config', 'G-PHLBPVWMT8', {'page_title': 'Closing'});

  useEffect(()=> {
    window.scrollTo(0, 0);
  },[]);

  return (
    <>
      <div className="container">
        <Language />
        <div className="Prologue">
          <div className="stars" style={{
            background: '#000 url(/img/stars.png) repeat top center'
          }}></div>
          <div className="twinkling" style={{
            background: 'transparent url(/img/twinkling.png) repeat top center'
          }}></div>
          <div className="Prologue__brand">
            <img src="/img/universe-logo-1.png" alt=""></img>
          </div>
          <div className="Prologue__title">
            UNIVERSE K-POP CONCERT
          </div>
          <div className="Closing__sentence">
            {config.dict[context.language].closing.sentence[0]}<br/>
            {config.dict[context.language].closing.sentence[1]}
          </div>
        </div>
        {
            process.env.REACT_APP_ENV === 'qa' && (
            <>
            <div onClick={()=>{context.changeContext({forceStage: 0})}}>
            <div
              style={{
                position: "absolute",
                left: "20px",
                top: "20px",
                color: "white",
                fontSize: "20px",
                cursor: 'pointer',
                zIndex: 1,
              }}
            >
              처음으로
            </div>
          </div>
            </>)
          }
      </div>
      <Guide />
      <div className="container"><Installation/></div>
    </>
  );
}
