import {
  useState,
  useEffect,
  useContext,
  createRef
} from 'react';

import Context from '../context/context';

import config from '../config.js';

export default function GuideStandby(props) {
  const context = useContext(Context);

  return (
    <div className="Guide">
      {config.dict[context.language].guideStandby.map((elMain,idxMain)=> {
        return (
          <>
          <div className="Guide__title" key={'guideStandby-title-'+idxMain}>
            {elMain.title}
          </div>
          {
            elMain.sub.map((elSub, idxSub)=> {
              return (
                <>
                  <div className="Guide__subtitle"
                    key={'guideStandby-title-'+idxMain+'-sub-'+idxSub}
                    style={context.language==='ja' ? {wordBreak: 'break-all'} : {}}>
                    {elSub.subtitle}
                  </div>
                  {
                    elSub.numbering.map(elNumber=> {
                      return getGuideBody(elNumber, context)
                    })
                  }
                </>
              )
            })
          }
          </>
        )
      })}
      
    </div>
  );
  
}

/////
const getGuideBody = (_el, context) => {
  const titleDom = _el.numberTitle ? (<div className="Guide__numbering">{_el.numberTitle}</div>) : null;
  const body = _el.body.map(bodyEl=> {
    const makeLink = (bodyEl) => {
      const idx = bodyEl.search(/\<\<link\>\>([\s\S]*?)\<\<\/link\>\>/);
      if (idx !== -1) {
        const matchedString = bodyEl.match(/\<\<link\>\>([\s\S]*?)\<\<\/link\>\>/)[0];
        const linkDom = (
          <a href={matchedString.replace('<<link>>','').replace('<</link>>','')} target="_blank">
            {matchedString.replace('<<link>>','').replace('<</link>>','')}
          </a>
        );
        const erasedString = bodyEl.replace(matchedString,'');
        let text1 = erasedString.slice(0,idx);
        let text2 = erasedString.slice(idx);
        return (<><span>{text1}</span>{linkDom}<span>{text2}</span></>)
      } else {
        return (<span>{bodyEl}</span>);
      }
    }
    return (
      <div className="Guide__body" style={context.language==='ja' ? {wordBreak: 'break-all'} : {}}>
        {makeLink(bodyEl)}
      </div>
    );
  });

  return [titleDom,body];
}