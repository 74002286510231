import {
  useState,
  useEffect,
  useContext,
  createRef
} from 'react';

import Context from '../context/context';
import config from '../config.js';

export default function Installation(props) {
  const context = useContext(Context);

  return (
    <div className="Installation">
      <div className="Installation__title">
        UNI-KON
      </div>
      <div className="Installation__tag" style={context.language==='ja' ? {wordBreak: 'break-all'} : {}}>
        {config.dict[context.language].installation.tag}
      </div>
      <div className="Installation__content">
        <div className="Installation__content__logo">
          <div style={{
            background: 'url(img/appicon.png) no-repeat 50% 50%',
            backgroundSize: 'contain',
          }}>
          </div>
        </div>
        <div className="Installation__content__info">
          <div className="Installation__content__info__row">
            <div><p>{config.dict[context.language].installation.h1}</p>{config.dict[context.language].installation.t1}</div>
            <div><p>{config.dict[context.language].installation.h2}</p>{config.dict[context.language].installation.t2}</div>
            <div><p>{config.dict[context.language].installation.h3}</p>{config.dict[context.language].installation.t3}</div>
          </div>
          <div className="Installation__content__info__link">
            <a
              href="https://play.google.com/store/apps/details?id=com.ncsoft.universeapp"
              style={{background: 'url(img/btn_google.png) no-repeat', backgroundSize: 'cover'}}
              target="_blank">
            </a>
            <a
              href="https://apps.apple.com/kr/app/id1515427473"
              style={{background: 'url(img/btn_apple.png) no-repeat', backgroundSize: 'cover'}}
              target="_blank">
            </a>
          </div>
        </div>
      </div>
    </div>
  );
  
}