import { useContext, useEffect } from "react";
import Context from '../context/context';
import config from '../config.js';

import {
    HashRouter,
    Router,
    Route,
    Switch,
    Redirect,
    withRouter,
    Link,
} from "react-router-dom";

import Countdown from './Countdown';
import Language from './Language';
import Installation from "./Installation";
import GuideStandby from "./GuideStandby";

export default function Standby() {
  const context = useContext(Context);

  window.gtag('config', 'G-PHLBPVWMT8', {'page_title': 'Standby'});

  useEffect(()=> {
    window.scrollTo(0, 0);
  },[]);

  return (
    <>
      <div className="container">
        <Language />
        <div className="Prologue">
          <div className="stars" style={{
            background: '#000 url(/img/stars.png) repeat top center'
          }}></div>
          <div className="twinkling" style={{
            background: 'transparent url(/img/twinkling.png) repeat top center'
          }}></div>
          <div className="Prologue__brand">
            <img src="/img/universe-logo-1.png" alt=""></img>
          </div>
          <div className="Prologue__title">
            UNIVERSE K-POP CONCERT
          </div>
          <div className="Standby">
            <div className="Standby__invitation">
              {
                makeSentence(config.dict[context.language].standby.invitation)
              }
            </div>
            <div className="Standby__countdown">
              <Countdown endDate={`2021-02-14T17:50:00+09:00`} />
            </div>
          </div>
          {
            process.env.REACT_APP_ENV === 'qa' && (
            <>
            <div onClick={()=>{context.changeContext({forceStage: 2})}}><div style={{
              position: 'absolute', left: '20px', top: '20px',
              color: 'white', fontSize: '20px', zIndex: 1, cursor: 'pointer'
            }}>이동</div></div>
            </>)
          }
        </div>
      </div>
      <div className="container"><Installation/></div>
      <GuideStandby />
    </>
  );
}

///
const makeSentence = (arr) => {
  const result = arr.map(el=> {
    if (el.type === 'emphasis') {
      return (<span>{el.text}</span>);
    } else {
      return (<>{el.text}</>)
    }
  });
  return result;
}