import React from "react";

const Context = React.createContext({
  language: null,
  stage: 0,
  forceStage: null,
  singleUrl: null,
  multiUrl: null,
  youtubeUrl: null,
  maxBitrate: null,
  changeContext: () => {}
});

export default Context;