import {
  useState,
  useEffect,
  useContext,
  createRef
} from 'react';
import uuid from 'react-uuid';
import Amplify, { Auth } from "aws-amplify";
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import AWSConnector from "./AWSConnector";
import gql from 'graphql-tag';

import Context from '../context/context';

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: "ap-northeast-1:8aced1f0-0beb-4e65-8ddb-2ae2cbf98584",
 
    // REQUIRED - Amazon Cognito Region
    region: "ap-northeast-1",
 
    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: "ap-northeast-1",
 
    aws_appsync_graphqlEndpoint: "https://kighkkn63fcaphxrwojgcy4vci.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    aws_appsync_region: "ap-northeast-1",
    aws_appsync_authenticationType: "AWS_IAM",
  },
});

const awsconfig = Auth.configure();

export default function AppSync(props) {
  const context = useContext(Context);
  const { username, addState } = props;

  const sync = async () => {
    try {
      const userFromSession = JSON.parse(sessionStorage.getItem('anon_user'));

      var user = {
        username: null,
        deviceKey: null
      };

      if (userFromSession) {
        user.username = userFromSession.username;
        user.deviceKey = userFromSession.deviceKey;
      }

      if (!user.username && username === 'planB') {
        user.username = uuid();
        user.deviceKey = 'anonymous';
      }

      if(!window.client) {
        console.log('let me make client');
        window.client = new AWSAppSyncClient({
          url: awsconfig.aws_appsync_graphqlEndpoint,
          region: awsconfig.aws_appsync_region,
          auth: {
            type: AUTH_TYPE.AWS_IAM,
            credentials: () => AWSConnector.getCredentials(),
          },
          disableOffline: true
        });

        window.client.subscribe({
          query: gql`
            subscription OnUpdateClientUserConnection($id: ID!) {
              onUpdateClientUserConnection(id: $id) {
                id
                deviceId
              }
            }
          `,
          variables: {id: user.username}
        }).subscribe({
          next: data => {
            console.log('hi');
          },
          error: error => {
            console.warn(error);
          }
        }); 
      }

      await window.client.mutate({
        mutation: gql`
          mutation UpdateClientUserConnection($id: ID!, $deviceId: String!) {
            updateClientUserConnection(id: $id, deviceId: $deviceId) {
              id
              deviceId
            }
          }
        `,
        variables: {id: user.username, deviceId: user.deviceKey }
      }).then(data => {
      
      });

      sessionStorage.setItem('anon_user', JSON.stringify(user));

    } catch (error) {
      console.log("error sync", error);
    }
  };

  useEffect(()=> {
    sync();
  },[]);

  return (<></>);
}