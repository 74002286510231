const awsmobile =  {
    "aws": {
        region: "ap-northeast-1"
    },
    "cognito": {
        IdentityPoolId: 'ap-northeast-1:8aced1f0-0beb-4e65-8ddb-2ae2cbf98584'
    }
};

export default awsmobile;
