import {
  useState,
  useEffect,
  useContext,
  createRef
} from 'react';
import TOTP from 'totp.js';
import uuid from 'react-uuid';
import Amplify, { Auth } from "aws-amplify";
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import AWSConnector from "./AWSConnector";
import gql from 'graphql-tag';

import Context from '../context/context';

import config from '../config.js';

import { TextField } from '@material-ui/core';

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: "ap-northeast-1:8aced1f0-0beb-4e65-8ddb-2ae2cbf98584",
 
    // REQUIRED - Amazon Cognito Region
    region: "ap-northeast-1",
 
    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: "ap-northeast-1",
 
    aws_appsync_graphqlEndpoint: "https://kighkkn63fcaphxrwojgcy4vci.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    aws_appsync_region: "ap-northeast-1",
    aws_appsync_authenticationType: "AWS_IAM",
  },
});

const awsconfig = Auth.configure();

export default function InputOtp(props) {
  const context = useContext(Context);
  const { code1, code2, code3, addState, addAppState } = props;
  const [state, setState] = useState({
    allTyped: false,
    fail: false,
  });
  const input2Ref = createRef();
  const input3Ref = createRef();

  window.gtag('config', 'G-PHLBPVWMT8', {'page_title': 'InputOtp'});

  const signUp = async (username, password, code, addState, addAppState) => {
    try {

    } catch (error) {
      console.log("error signing up:", error);
    }
  };

  const signIn = async (username, password, code, addState, addAppState) => {  
    try {
      username = username.toUpperCase();
      const totp = new TOTP(TOTP.base32.encode(username), 4);
      const isValid = totp.verify(code, 180);
      if (!isValid) {
        throw Error('invalid code');
      }

      const deviceKey = uuid();
      var user = {
        error: !isValid,
        username: username,
        deviceKey: deviceKey
      };
 
      if(!window.client) {
        console.log('let me make client');
        window.client = new AWSAppSyncClient({
          url: awsconfig.aws_appsync_graphqlEndpoint,
          region: awsconfig.aws_appsync_region,
          auth: {
            type: AUTH_TYPE.AWS_IAM,
            credentials: () => AWSConnector.getCredentials(),
          },
          disableOffline: true
        });

        window.client.subscribe({
          query: gql`
            subscription OnUpdateClientUserConnection($id: ID!) {
              onUpdateClientUserConnection(id: $id) {
                id
                deviceId
              }
            }
          `,
          variables: {id: username}
        }).subscribe({
          next: data => {
            if (data.data.onUpdateClientUserConnection.deviceId !== deviceKey) {
              addAppState({
                modalOpen: true,
                modalType: 'duplicate'
              });
              signOut();
              setTimeout(() => {
                addState({
                  codePassed: false,
                  code3: ''
                });
              }, 1500);
              
              sessionStorage.removeItem('user');
              console.log('signed out');
            }
          },
          error: error => {
            console.warn(error);
          }
        }); 
      }

      await window.client.mutate({
        mutation: gql`
          mutation UpdateClientUserConnection($id: ID!, $deviceId: String!) {
            updateClientUserConnection(id: $id, deviceId: $deviceId) {
              id
              deviceId
            }
          }
        `,
        variables: {id: username, deviceId: deviceKey}
      }).then(data => {
      
      });

      sessionStorage.setItem('user', JSON.stringify(user));
      return user;

    } catch (error) {
      console.log("error signing in", error);
      return {...error, error: true};
    }
  };

  const keepSignIn = async (addState, addAppState) => {
    try {
      const user = JSON.parse(userFromSession);

      const deviceKey = user.deviceKey;
        
      if(!window.client) {
        console.log('let me make client');
        window.client = new AWSAppSyncClient({
          url: awsconfig.aws_appsync_graphqlEndpoint,
          region: awsconfig.aws_appsync_region,
          auth: {
            type: AUTH_TYPE.AWS_IAM,
            credentials: () => AWSConnector.getCredentials(),
          },
          disableOffline: true
        });

        window.client.subscribe({
          query: gql`
            subscription OnUpdateClientUserConnection($id: ID!) {
              onUpdateClientUserConnection(id: $id) {
                id
                deviceId
              }
            }
          `,
          variables: {id: user.username}
        }).subscribe({
          next: data => {
            if (data.data.onUpdateClientUserConnection.deviceId !== deviceKey) {
              addAppState({
                modalOpen: true,
                modalType: 'duplicate'
              });
              signOut();
              setTimeout(() => {
                addState({
                  codePassed: false,
                  code3: ''
                });
              }, 1500);
              
              sessionStorage.removeItem('user');
              console.log('signed out');
            }
          },
          error: error => {
            console.warn(error);
          }
        }); 
      }

      await window.client.mutate({
        mutation: gql`
          mutation UpdateClientUserConnection($id: ID!, $deviceId: String!) {
            updateClientUserConnection(id: $id, deviceId: $deviceId) {
              id
              deviceId
            }
          }
        `,
        variables: {id: user.username, deviceId: deviceKey}
      }).then(data => {
      
      });

      sessionStorage.setItem('user', JSON.stringify(user));
      return user;

    } catch (error) {
      console.log("error signing in", error);
      return {...error, error: true};
    }
  };


  const signOut = async () => {
    try {
        
    } catch (error) {
        console.log('error signing out: ', error);
    }
  }

  const handleSubmitCode = async () => {
    if (!state.allTyped) {
      return;
    }
    
    addState({loading: true});
    const userAuth = await signIn(state.username, state.password, state.code, addState, addAppState);
    console.log(userAuth);
    if (!userAuth?.error) {
      addState({
        codePassed: true,
        loading: false
      });
    } else {
      addState({
        code1: '',
        code2: '',
        code3: '',
        loading: false,
      });
      setState({
        ...state,
        fail: true,
        allTyped: false,
      })
    }
  }

  const handlePase = (e, start) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');
    let newState = {};
    if (start === 1) {
      newState.code1 = text.slice(0,4);
      if (text.length > 4) {
        newState.code2 = text.slice(4,8);
      }
      if (text.length > 8) {
        newState.code3 = text.slice(8,12);
      }
      if (text.length >= 4 && text.length < 8) input2Ref.current.focus();
      if (text.length >= 8) input3Ref.current.focus();
      addState(newState);
    }
    if (start === 2) {
      newState.code2 = text.slice(0,4);
      if (text.length > 4) {
        newState.code3 = text.slice(4,8);
      }
      if (text.length >= 4) input3Ref.current.focus();
      addState(newState);
    }
  }

  const userFromSession = sessionStorage.getItem('user');

  useEffect(()=> {
    let allTyped = (code1.length === 4) && (code2.length === 4) && (code3.length === 4);
    const username = (code1+code2).toLowerCase();
    const password = (code1+code2).toLowerCase();
    const code = code3;
    setState({...state, allTyped: allTyped,
      username: username, password: password, code: code
    });
    if (userFromSession) {
      keepSignIn(addState, addAppState);
      addState({
        codePassed: true
      });
    };
  },[code1, code2, code3]);

  return (
    <div className="Entrance__input">
      <div className="Entrance__input__title">
        {config.dict[context.language].otp.title}
      </div>
      <TextField
        className="Entrance__input__box"
        id="textfield-input-code1"
        variant="standard"
        size="small"
        autoFocus
        value={code1}
        onChange={(e)=>{
          addState({code1: e.target.value.toUpperCase()});
          if ( e.target.value.length > 3) input2Ref.current.focus();
        }}
        inputProps={{ maxLength: 4 }}
        onPaste={(e)=>{handlePase(e, 1)}}
      />
      <span> - </span>
      <TextField
        inputRef={input2Ref}
        className="Entrance__input__box"
        id="textfield-input-code2"
        variant="standard"
        size="small"
        value={code2}
        onChange={(e)=>{
          addState({code2: e.target.value.toUpperCase()});
          if ( e.target.value.length > 3) input3Ref.current.focus();
        }}
        inputProps={{ maxLength: 4 }}
        onPaste={(e)=>{handlePase(e, 2)}}
      />
      <span> - </span>
      <TextField
        inputRef={input3Ref}
        className="Entrance__input__box"
        id="textfield-input-code3"
        variant="standard"
        size="small"
        value={code3}
        onChange={(e)=>{addState({code3: e.target.value.toUpperCase()})}}
        inputProps={{ maxLength: 4 }}
      />
      {
        state.fail ? (
          <div className="Entrance__input__info1 wrong">
            {config.dict[context.language].otp.invalid}
          </div>
        ) :(
          <div className="Entrance__input__info1" style={context.language==='ja' ? {wordBreak: 'break-all'} : {}}>
            {config.dict[context.language].otp.info1}
          </div>
        )
      }

      <div className="Entrance__input__info2">
        {config.dict[context.language].otp.info2[0]}<br/>
        {config.dict[context.language].otp.info2[1]}
      </div>
      <button onClick={handleSubmitCode} className={
          "Entrance__button" + (state.allTyped? "" : " inactive")
        }
      >
        {config.dict[context.language].otp.button}
      </button>
    </div>
  );
}