import { useContext } from "react";
import Context from '../context/context';

import config from '../config.js';

export default function Footer() {
  const context = useContext(Context);

  return (
    <div className="Footer">
      <div className="Footer__linkContainer">
        <div className="Footer__linkContainer__link">
          <a target="_blank" href={config.dict[context.language].service.url}>{config.dict[context.language].service.name}</a>
        </div>
        <span>|</span>
        <div className="Footer__linkContainer__link">
          <a target="_blank" href={config.dict[context.language].privacy.url}>{config.dict[context.language].privacy.name}</a>
        </div>
        <span>|</span>
        <div className="Footer__linkContainer__link">
          <a target="_blank" href={config.dict[context.language].cs.url}>{config.dict[context.language].cs.name}</a>
        </div>
        <span>|</span>
        <div className="Footer__linkContainer__link">
          <a target="_blank" href={`mailto: ${config.dict[context.language].biz.url}`}>{config.dict[context.language].biz.name}</a>
        </div>
      </div>
      <div className="Footer__copyright">
        © NCSOFT Corporation. All Rights Reserved.
      </div>
    </div>
  );
}
