import { useState, useEffect, useContext } from "react";
import Context from "../context/context";

export default function FetchMediaUrl(props) {
  const context = useContext(Context);

  const [state, setState] = useState({
    multiUrl: null,
    singleUrl: null,
    youtubeUrl: null,
    maxBitrate: null,
  });

  const changeState = (input) => {
    setState((prevState) => 
    ({...prevState, ...input}));
  };

  const fetchMediaUrl = async () => {
    
    try {

      const response = await fetch('/status/stream', {cache: "no-cache"});
      const data = await response.json();

      let result = {
        singleUrl: data.single_live,
        multiUrl: data.multiangle_live,
        youtubeUrl: null,
        maxBitrate: data.max_bitrate
      };

      if (data.single_live.indexOf('youtube.com') > -1) {
        result = {
          ...result,
          youtubeUrl: data.single_live,
          forceStage: 11,
        };
      }
      changeState(result);
      setTimeout(()=>{changeState({inPeriod: false})}, 1000*60);
    } catch(e) {
      changeState({
        singleUrl: 'https://stream01.uni-kon.universe-official.io/live/cbc1/m2ts/Single-NC-UniKon/manifest.m3u8',
        multiUrl: 'https://stream01.uni-kon.universe-official.io/multianglelive/cbc1/m2ts/Multi-NC-UniKon/manifest.m3u8',
      });
      setTimeout(()=>{changeState({inPeriod: false})}, 1000*60);
    }
  }

  useEffect(() => {
    if (!state.inPeriod && context.forceStage !== 11) {
      fetchMediaUrl();
      changeState({inPeriod: true});
    }
    
    if (state.singleUrl &&  (
          state.singleUrl !== context.singleUrl ||
          state.multiUrl !== context.multiUrl ||
          state.maxBitrate !== context.maxBitrate
    )) {

      let newContext = {
        singleUrl: state.singleUrl,
        multiUrl: state.multiUrl,
        youtubeUrl: state.youtubeUrl,
        maxBitrate: state.maxBitrate
      }
      if (state.forceStage) {
        newContext = { ...newContext, forceStage: state.forceStage }
      }
      context.changeContext(newContext);
    }
  },[state]);

  return(<></>);
}
