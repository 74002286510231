import { useContext, useEffect } from "react";
import Context from '../context/context';

import {
    HashRouter,
    Router,
    Route,
    Switch,
    Redirect,
    withRouter,
    Link,
} from "react-router-dom";

import Language from './Language';
import Installation from "./Installation";
import GuidePrologue from "./GuidePrologue";

import config from '../config.js';

const getDateString = (_date) => {
  const year = _date.getFullYear();
  const month = _date.getMonth() + 1;
  const date = _date.getDate();
  const hours = _date.getHours();
  const mins = _date.getMinutes();
  return `${year}.${month < 10 ? '0' : ''}${month}.${date < 10 ? '0' : ''}${date}.`
  + ` ${hours}:${mins < 10 ? '0' : ''}${mins}`
}


export default function Prologue() {
  const context = useContext(Context);

  window.gtag('config', 'G-PHLBPVWMT8', {'page_title': 'Prologue'});

  useEffect(()=> {
    window.scrollTo(0, 0);
  },[]);

  return (
    <>
      <div className="container">
        <Language />
        <div className="Prologue">
          <div className="stars" style={{
            background: '#000 url(/img/stars.png) repeat top center'
          }}></div>
          <div className="twinkling" style={{
            background: 'transparent url(/img/twinkling.png) repeat top center'
          }}></div>
          <div className="Prologue__brand">
            <img src="/img/universe-logo-1.png" alt=""></img>
          </div>
          <div className="Prologue__title">
            UNIVERSE K-POP CONCERT
          </div>
          <div className="Prologue__info">
            <p>COMING SOON</p>
            <p>{config.dict[context.language].infoOpenAt} : {getDateString(new Date(config.openAt))} (KST)</p>
            <p>{config.dict[context.language].infoEventAt} : {getDateString(new Date(config.eventTime))} (KST)</p>
          </div>
          {
            process.env.REACT_APP_ENV === 'qa' && (
            <>
            <div onClick={()=>{context.changeContext({forceStage: 1})}}><div style={{
              position: 'absolute', left: '20px', top: '20px',
              color: 'white', fontSize: '20px', zIndex: 1, cursor: 'pointer'
            }}>이동</div></div>
            <div onClick={()=>{context.changeContext({forceStage: 3})}}><div style={{
              position: 'absolute', left: '80px', top: '20px',
              color: 'white', fontSize: '20px', zIndex: 1, cursor: 'pointer'
            }}>종료</div></div>
            <div onClick={()=>{context.changeContext({forceStage: 4})}}><div style={{
              position: 'absolute', left: '20px', top: '10%',
              color: 'white', fontSize: '20px', zIndex: 1, cursor: 'pointer'
            }}>무료입장</div></div>
            </>)
          }
        </div>
      </div>
      <div className="container"><Installation/></div>
      <GuidePrologue />
    </>
  );
}
