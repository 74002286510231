import {
  useState,
  useEffect,
  useContext,
} from 'react';

import Context from '../context/context';

import config from '../config.js';

import AppSync from './AppSync'

import Live from './Live';
import Language from './Language';
import Installation from "./Installation";
import Guide from "./GuideEntrance";

export default function PlanB(props) {
  const context = useContext(Context);
  const { addAppState } = props;

  const [state, setState] = useState({
    codePassed: false,
    username: 'planB',
  });

  const handleClick = () => {
    setState({
      ...state,
      codePassed: true
    })
  }
  
  const addState = (_obj) => {
    setState({
      ...state,
      ..._obj
    });
  }

  useEffect(()=> {
    window.scrollTo(0, 0);
  },[state.codePassed]);

  if (state.codePassed) {
    window.gtag('config', 'G-PHLBPVWMT8', {'page_title': 'PlanB'});
    return (
      <>
        <Live videoUrl={state.videoUrl} nickname={state.nickname} playerInit={state.playerInit}
          addState={addState}
        />
        <AppSync username={state.username} addState={addState}/>
        <Guide />
        <div className="container"><Installation/></div>
      </>
    );
  } else {
    return (
      <>
        <div className="container">
          <Language />
          <div className="Prologue">
            <div className="stars" style={{
              background: '#000 url(/img/stars.png) repeat top center'
            }}></div>
            <div className="twinkling" style={{
              background: 'transparent url(/img/twinkling.png) repeat top center'
            }}></div>
            <div className="Prologue__brand">
              <img src="/img/universe-logo-1.png" alt=""></img>
            </div>
            <div className="Prologue__title">
              UNIVERSE K-POP CONCERT
            </div>
            <div className="Standby">
              <div className="Standby__invitation">
                {
                  makeSentence(config.dict[context.language].standby.invitation)
                }
              </div>
            </div>
            <div className="PlanB__button" onClick={handleClick}>
              {config.dict[context.language].planB.button}
            </div>
            {
              process.env.REACT_APP_ENV === 'qa' && (
              <>
              <div onClick={()=>{context.changeContext({forceStage: 0})}}><div style={{
                position: 'absolute', left: '20px', top: '20px',
                color: 'white', fontSize: '20px', zIndex: 1, cursor: 'pointer'
              }}>처음으로</div></div>
              </>)
            }
          </div>
          {
            (window.navigator.userAgent.indexOf('MSIE') > 0 ||
              window.navigator.userAgent.indexOf('Trident/') > 0 ||
              window.navigator.userAgent.indexOf("Edge/") > -1
            ) ?
              (<div className="avoidIE">
                <div className="avoidIE__message">
                {config.dict[context.language].avoidIE[0]}<br/>
                {config.dict[context.language].avoidIE[1]}
                </div>
              </div>) : null
          }
        </div>
        <div className="container"><Installation/></div>
        <Guide />
      </>
    );
  }
}

///
const makeSentence = (arr) => {
  const result = arr.map(el=> {
    if (el.type === 'emphasis') {
      return (<span>{el.text}</span>);
    } else {
      return (<>{el.text}</>)
    }
  });
  return result;
}