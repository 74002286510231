import { useState, useEffect, useContext } from "react";
import Context from "../context/context";

export default function CheckOtp(props) {
  const context = useContext(Context);

  const [state, setState] = useState({
    loadedOtpSwitch: false
  });

  const checkOtp = async () => {
    if (context.stage === 11) return;
    
    fetch('https://d2jwhcju8kc2go.cloudfront.net/status/otp', {cache: "no-cache"})
      .then(response => response.json())
      .then(data => {
        if (data.otp === 'nonotp' && context.forceStage !== 4 && context.forceStage !== 11) {
          context.changeContext({
            forceStage: 4
          });
        };
      });
  }

  useEffect(() => {
    if (state.loadedOtpSwitch === false && context.stage !== 4) {
      setState({loadedOtpSwitch: true});
      checkOtp();
    }
  },[context.stage, state.loadedOtpSwitch]);

  return(<></>);
}
