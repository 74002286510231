import {
    useState,
    useEffect
} from 'react';

export default function Countdown(props) {
    const { endDate } = props;

    window.gtag('config', 'G-PHLBPVWMT8', {'page_title': 'Countdown'});

    const [state, setState] = useState({
        days: 0,
        hours: 0,
        min: 0,
        sec: 0,
      });

    
    const calculateCountdown = (endDate) => {
        let diff = parseInt((new Date(endDate).getTime() - new Date().getTime()) / 1000);

        // clear countdown when date is reached
        if (diff <= 0) return false;

        const timeLeft = {
        years: 0,
        days: 0,
        hours: 0,
        min: 0,
        sec: 0
        };

        // calculate time difference between now and expected date
        if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
        timeLeft.years = Math.floor(diff / (365.25 * 86400));
        diff -= timeLeft.years * 365.25 * 86400;
        }
        if (diff >= 86400) {
        timeLeft.days = Math.floor(diff / 86400);
        diff -= timeLeft.days * 86400;
        }
        if (diff >= 3600) {
        timeLeft.hours = Math.floor(diff / 3600);
        diff -= timeLeft.hours * 3600;
        }
        if (diff >= 60) {
        timeLeft.min = Math.floor(diff / 60);
        diff -= timeLeft.min * 60;
        }
        timeLeft.sec = diff;

        return timeLeft;
    }

    const stop = () => {
        clearInterval(window.interval);
    }

    const addLeadingZeros = (value) => {
        value = String(value);
        while (value.length < 2) {
        value = '0' + value;
        }
        return value;
    }

    
    useEffect(()=> {
        window.interval = setInterval(() => {
            const newDate = calculateCountdown(endDate);
            newDate ? setState(newDate) : stop();
        }, 1000);
        return () => { stop() }
    });


    return (
      <div className="Countdown">
        {addLeadingZeros(state.hours + state.days*24)}:{addLeadingZeros(state.min)}:{addLeadingZeros(state.sec)}
      </div>
    );
}