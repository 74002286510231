import { useEffect, useState, useContext } from "react";

import Context from './context/context';

import Prologue from './components/Prologue';
import Standby from './components/Standby';
import Entrance from './components/Entrance';
import Closing from './components/Closing';
import Language from "./components/Language";
import Footer from "./components/Footer";
import Modal from "./components/Modal";
import PlanB from "./components/PlanB";
import Youtube from "./components/Youtube";
import CheckOtp from "./components/CheckOtp";
import FetchMediaUrl from "./components/FetchMediaUrl";

import config from './config.js';

const Content = (props) => {
  const { stage, addAppState } = props;

  if (stage === 0) return (<Prologue />);
  if (stage === 1) return (<Standby />);
  if (stage === 2) return (<Entrance addAppState={addAppState} />);
  if (stage === 3) return (<Closing />);
  if (stage === 4) return (<PlanB/>);
  if (stage === 11) return (<Youtube/>);
};

export default function App() {

  const defaultLang = () => {
    const langBrowser = window.navigator.language?.split('-')[0];
    if ( langBrowser === 'en' || langBrowser === 'ko' || langBrowser === 'ja') {
      return langBrowser;
    } else {
      return 'en';
    }
  }

  const defaultContext = {
    language: defaultLang(),
    stage: 0,
    forceStage: null,
    singleUrl: 'https://stream01.uni-kon.universe-official.io/live/cbc1/m2ts/Single-NC-UniKon/manifest.m3u8',
    multiUrl: 'https://stream01.uni-kon.universe-official.io/multianglelive/cbc1/m2ts/Multi-NC-UniKon/manifest.m3u8',
    youtubeUrl: null,
    maxBitrate: null,
  };

  const now = new Date().getTime();
  const eventTime = new Date(config.eventTime).getTime();
  const openAt = new Date(config.openAt).getTime();
  const standbyAt = new Date(config.standbyAt).getTime();
  const closeAt = new Date(config.closeAt).getTime();

  const [context, setContext] = useState(defaultContext);
  const [appState, setAppState] = useState({
    modalOpen: false,
    modalType: null,
    loadingOtpAvail : false,
    singleUrl: null,
    multiUrl: null,
  });

  const changeContext = (input) => {
    setContext((prevState) => 
    ({...prevState, ...input}));
  }

  const addAppState = (_obj) => {
    setAppState({
      ...appState,
      ..._obj,
    });
  };

  if (context.forceStage && context.forceStage !== context.stage) {
    changeContext({
      stage: context.forceStage,
    });
  } else if (!context.forceStage) {
    if (now < standbyAt && context.stage!==0) changeContext({stage: 0});
    if (now >= standbyAt && now < openAt && context.stage!==1) changeContext({stage: 1});
    if (now >=  openAt && now < closeAt && context.stage!==2) changeContext({stage: 2});
    if (now >=  closeAt && context.stage!==3) changeContext({stage: 3});
  }

  useEffect(()=>{

    window.addEventListener('offline', (e) => {
      addAppState({
        modalOpen: true,
        modalType: 'offline'
      });
    });

  },[window.navigator.onLine, context.stage]);
  
  return (
    <Context.Provider value={{ ...context, changeContext}}>
      <Content stage={context.stage} addAppState={addAppState}/>
      <Footer />
      <Modal modalOpen={appState.modalOpen} modalType={appState.modalType} addAppState={addAppState}/>
      <CheckOtp />
      <FetchMediaUrl />
    </Context.Provider>
  );
}