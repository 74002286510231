import { useState, useEffect, useContext } from "react";
import Context from '../context/context';

export default function Live(props) {
  const context = useContext(Context);

  window.gtag('config', 'G-PHLBPVWMT8', {'page_title': 'Live'});

  useEffect(() => {
    if (!window.multiPlayer) {
      window.gtag('config', 'G-PHLBPVWMT8', {'page_title': 'Multi View'});
      window.multiPlayer = window.multiviewPlayer("player-container", context.singleUrl, context.multiUrl, context.maxBitrate);
    } else {
      window.gtag('config', 'G-PHLBPVWMT8', {'page_title': 'High Quality View'});
      window.multiPlayer.load(context.singleUrl, context.multiUrl, context.maxBitrate);
    }
  }, [window.multiPlayer, context]);

  return (
    <div>
      <div className="Live">
        <div id="player-container"></div>
      </div>
    </div>
  );
}
