import {
  useState,
  useEffect,
  useContext,
  createRef
} from 'react';

import Context from '../context/context';

import config from '../config.js';

export default function Modal(props) {
  const context = useContext(Context);

  const {modalOpen, modalType, addAppState } = props;
  const [state, setState] = useState({
    confirmed: false
  });

  const handleModalClick = () => {
    addAppState({modalOpen: false});
  };

  const modalText = config.dict[context.language].modal[modalType];

  return (
    <div className={
      "Modal" + (modalOpen? "" : " hide")
    }>
      <div className="Modal__backdrop"></div>
      <div className="Modal__paper">
        <div className="Modal__paper__content">
          <div>{modalText}</div>
        </div>
        <div className="Modal__paper__action">
          <div className="Modal__paper__action__button"
            onClick={handleModalClick}
          >확인</div>
        </div>
      </div>
    </div>
  );
};