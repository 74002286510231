import { useState, useEffect, useContext } from "react";
import Context from "../context/context";

export default function Language(props) {
  const context = useContext(Context);

  useEffect(() => {
    window.language = context.language;
  },[context]);

  return (
    <div className="Language">
      <div
        className={
          "Language__button" + (context.language === "ko" ? " selected" : "")
        }
        onClick={()=>{context.changeContext({language: 'ko'})}}
      >
        KR
      </div>
      <div
        className={
          "Language__button" + (context.language === "en" ? " selected" : "")
        }
        onClick={()=>{context.changeContext({language: 'en'})}}
      >
        EN
      </div>
      <div
        className={
          "Language__button" + (context.language === "ja" ? " selected" : "")
        }
        onClick={()=>{context.changeContext({language: 'ja'})}}
      >
        JP
      </div>
    </div>
  );
}
