const config = {
    eventTime: '2121-02-14T19:00:00+09:00',
    openAt: '2121-02-14T17:50:00+09:00',
    standbyAt: '2121-02-13T17:50:00+09:00',
    closeAt: '2121-02-14T23:00:00+09:00',
    dict: {
      ko: {
        service: {
          name: '이용약관',
          url: 'https://policy.universe-official.io/policy/service/universe/ko'
        },
        privacy: {
          name: '개인정보처리방침',
          url: 'https://policy.universe-official.io/policy/privacy/universe/ko'
        },
        cs: {
          name: '고객센터',
          url: 'https://cs.universe-official.io?locale=ko'
        },
        biz: {
          name: '제휴문의',
          url: 'universe_biz@ncsoft.com'
        },
        infoOpenAt: '사이트 오픈',
        infoEventAt: '콘서트 시작',
        modal: {
          duplicate: '새로운 기기에서 접속이 발견되었습니다.',
          offline: '네트워크가 불안정합니다. 네트워크 상태를 확인해주세요.'
        },
        guidePrologue: [
          {
            title: '콘서트 안내',
            sub: [
              {
                subtitle: '공연일시',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '2021년 2월 14일(일) 19:00(KST)'
                    ]
                  }
                ]
              },
              {
                subtitle: '시청 안내',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '스트리밍은 모바일과 PC에서 모두 이용 가능하나, ',
                      '1개의 계정당 1개의 기기에서 무료 스트리밍할 수 있습니다.',
                      '동시 접속은 불가능하며, 동시 접속 시 기존 기기는 자동 로그아웃됩니다.',
                      '실시간 라이브는 총 5개의 멀티뷰 화면을 제공하며, 1개의 메인 뷰와 4개의 시점 뷰로 이루어집니다.',
                      '콘서트 자막은 한국어 / 영어 / 일어 / 자막 없음 총 4가지로 지원합니다.' ,
                      '콘서트 페이지는 2월 14일 17시 50분부터  OTP 발급을 통해 접속 가능합니다!',
                      '미리 접속해서 네트워크 연결을 확인해주세요.',
                      '해당 콘서트는 UNIVERSE 공식 유튜브 채널에서도 시청하실 수 있습니다.',
                      '단, 유튜브 채널에서는 싱글뷰와 자막 없이 화면이 송출됩니다.'
                    ]
                  }
                ]
              },
              {
                subtitle: '콘서트 페이지 입장 방법',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'Step 1. UNIVERSE 앱 로그인 ',
                      'Step 2. UNIVERSE 홈 입장 후, 콘서트 배너 클릭 ',
                      'Step 3. OTP 발급받기',
                      'Step 4. 시청할 플랫폼 이동 (PC 웹 (<<link>>https://uni-kon.universe-official.io<</link>>) /모바일 웹)',
                      'Step 5. 발급받은 OTP 입력',
                      'Step 6. 콘서트 페이지 입장 및 콘서트 시청'
                    ]
                  }
                ]
              },
              {
                subtitle: '재생 지원 환경',
                numbering: [
                  {
                    numberTitle: '1. 기기 확인',
                    body: [
                      'PC OS: Mac OS 10.12+ / Window 7+',
                      'Mobile OS: iOS 11+ / Android 8 +',
                      'PC 브라우저 : Chrome, Edge, Safari'
                    ]
                  },
                  {
                    numberTitle: '2. 환경설정',
                    body: [
                      '원활한 시청을 위해 인터넷 속도 20 Mbps 이상의 환경에서 접속해주시기 바랍니다.',
                      '공용 네트워크에서는 20 Mbps 이상이더라도 접속이 원활하지 않을 수 있습니다.',
                      '3G/LTE 이용 시 데이터 사용량이 증가할 수 있습니다.',
                      '영상 화질은 자동으로 조정됩니다.',
                      '통신/데이터 상황 및 기타 외부 요인에 의해 영상, 음성에 차질이 생길 수 있는 점 ',
                      '양해 후 시청해주시기 바랍니다.'
                    ]
                  }
                ]
              }
            ]
          },
          {
            title: 'FAQ',
            sub: [
              {
                subtitle: 'Q. UNI-KON은 어디서 볼 수 있나요?',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'UNIVERSE 앱을 다운로드하여 로그인하신 뒤, UNI-KON배너를 클릭하면 콘서트 페이지로 이동하게 됩니다.',
                      '이동 후, OTP 인증을 한 뒤 모바일 웹 또는 PC 웹에서 시청 가능합니다.'
                    ]
                  }
                ]
              },
              {
                subtitle: 'Q. UNI-KON은 모바일에서 어떻게 보나요?',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '1. UNIVERSE 앱 다운 및 로그인',
                      '2. UNIVERSE Home 화면에서 콘서트 배너 클릭 시 콘서트 페이지로 이동',
                      '3. OTP 발급받은 뒤, 모바일 웹으로 보기 클릭 및 이동',
                      '4. 발급받은 OTP 입력',
                      '5. 라이브 스트리밍이 시작되면, 공연 즐기기'
                    ]
                  }
                ]
              },
              {
                subtitle: 'Q. UNI-KON을 PC 웹에서 보고 싶을 때 어떻게 보나요?',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '1. UNIVERSE 앱 다운 및 로그인',
                      '2. UNIVERSE Home 화면에서 콘서트 배너 클릭 시, 콘서트 페이지로 이동',
                      '3. OTP 발급받은 뒤, PC 웹 (<<link>>https://uni-kon.universe-official.io<</link>>)으로 보기 클릭 및 이동',
                      '4. 발급받은 OTP를 PC 웹에 입력 후 및 콘서트 즐기기.'
                    ]
                  }
                ]
              },
              {
                subtitle: 'Q. UNIVERSE 앱 로그인하고 콘서트 배너 클릭을 했는데, 콘서트 화면 페이지로 이동이 안돼요.',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '본 공연의 콘서트 페이지 이동 및 입장은 1시간 전부터 가능하며,',
                      '해당 시간에 맞추어 OTP 발급받기 버튼이 활성화됩니다.'
                    ]
                  }
                ]
              },
              {
                subtitle: 'Q. 공연을 시청하기 위해 사용할 수 있는 디바이스 환경은 어떤 것들이 있나요?',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'PC를 이용하실 경우, Chrome, Edge, Safari를 지원합니다. IE는 지원하지 않습니다.'
                    ]
                  }
                ]
              },
              {
                subtitle: 'Q. 큰 화면에서 공연을 시청하고 싶은데 어떻게 해야 하나요?',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '사용자의 모바일 및 TV 기능에 따라 미러링을 이용하실 수 있습니다.',
                      '자세한 이용법은 보유한 기기의 사용 안내서를 확인해주세요.',
                      '만약 보유한 기기에서 미러링이 지원되지 않는 경우, 컴퓨터나 모바일 기기를 HDMI를 통해 ',
                      'TV나 모니터에 연결하여 큰 화면으로 공연을 즐길 수 있습니다.'
                    ]
                  }
                ]
              },
              {
                subtitle: 'Q. 동시에 여러 개의 기기로 공연을 볼 수 있나요?',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '1 계정당 1 기기에서만 공연을 시청할 수 있습니다.  다른 기기 동시 접속 시 자동 로그아웃됩니다.',
                    ]
                  }
                ]
              },
              {
                subtitle: 'Q. 라이브 공연이 끝난 후에 공연을 다시 볼 수 있나요?',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '본 공연은 다시 보기 VOD 서비스로 추후 발매될 예정입니다.',
                    ]
                  }
                ]
              }
            ]
          },
          {
            title: '안내 사항',
            sub: [
              {
                subtitle: '법적 고지',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '콘텐츠를 무단 배포 및 가공하는 행위는 저작권 침해에 해당합니다. 콘서트를 임의로 녹화하여 추출하는 행위, 다수의 시청이 가능한 곳에서 중계하는 행위 등은 엄격히 금지하고 있습니다.',
                      '상기 불법행위 혹은 저작권자의 동의 없이 콘텐츠를 배포 및 가공하는 행위가 적발될 경우, 저작권 침해에 관한 법적인 제재를 받을 수 있습니다.'
                    ]
                  }
                ]
              },
              {
                subtitle: '기타 안내',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '현장 중계 사정에 따라 러닝타임 및 프로그램이 변경될 수 있습니다.',
                      '본 공연은 다시 보기 VOD 서비스로 추후 발매될 예정입니다.',
                      '콘서트 관련 문의는 FAQ 혹은 고객센터 1:1 문의를 이용해주시기 바랍니다.'
                    ]
                  }
                ]
              }
            ]
          }
        ],
        guideStandby: [
          {
            title: 'UNI-KON 관람 안내 및 주의 사항',
            sub: [
              {
                subtitle: '시청 안내',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '스트리밍은 모바일과 PC에서 모두 이용 가능하나, ',
                      '1개의 계정 당 1개의 기기에서 무료 스트리밍할 수 있습니다.',
                      '동시 접속은 불가능하며, 동시 접속 시 기존 기기는 자동 로그아웃됩니다.',
                      '실시간 라이브는 총 5개의 멀티뷰 화면을 제공하며, 1개의 메인 뷰와 4개의 시점 뷰로 이루어집니다.',
                      '콘서트 자막은 한국어 / 영어 / 일어 / 자막 없음 총 4가지로 지원합니다.' ,
                      '콘서트 페이지는 2월 14일 17시 50분부터  OTP 발급을 통해 접속 가능합니다!',
                      '미리 접속해서 네트워크 연결을 확인해주세요.',
                      '해당 콘서트는 UNIVERSE 공식 유튜브 채널에서도 시청하실 수 있습니다.',
                      '단, 유튜브 채널에서는 싱글뷰와 자막 없이 화면이 송출됩니다.'
                    ]
                  }
                ]
              },
              {
                subtitle: '재생 지원 환경',
                numbering: [
                  {
                    numberTitle: '1. 기기 확인',
                    body: [
                      'PC OS: Mac OS 10.12+ / Window 7+',
                      'Mobile OS: iOS 11+ / Android 8 +',
                      'PC 브라우저 : Chrome, Edge, Safari'
                    ]
                  },
                  {
                    numberTitle: '2. 환경설정',
                    body: [
                      '원활한 시청을 위해 인터넷 속도 20 Mbps 이상의 환경에서 접속해주시기 바랍니다.',
                      '공용 네트워크에서는 20 Mbps 이상이더라도 접속이 원활하지 않을 수 있습니다.',
                      '3G/LTE 이용 시 데이터 사용량이 증가할 수 있습니다.',
                      '영상 화질은 자동으로 조정됩니다.',
                      '통신/데이터 상황 및 기타 외부 요인에 의해 영상, 음성에 차질이 생길 수 있는 점 양해 후 시청해주시기 바랍니다.'
                    ]
                  }
                ]
              },
              {
                subtitle: '콘서트 페이지 입장 방법',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'Step 1. UNIVERSE 앱 로그인 ',
                      'Step 2. UNIVERSE 홈 입장 후, 콘서트 배너 클릭 ',
                      'Step 3. OTP 발급받기',
                      'Step 4. 시청할 플랫폼 이동 (PC 웹 (<<link>>https://uni-kon.universe-official.io<</link>>) /모바일 웹)',
                      'Step 5. 발급받은 OTP 입력',
                      'Step 6. 콘서트 페이지 입장 및 콘서트 시청'
                    ]
                  }
                ]
              },
              {
                subtitle: '법적 고지',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '콘텐츠를 무단 배포 및 가공하는 행위는 저작권 침해에 해당합니다. 콘서트를 임의로 녹화하여 추출하는 행위, 다수의 시청이 가능한 곳에서 중계하는 행위 등은 엄격히 금지하고 있습니다.',
                      '상기 불법행위 혹은 저작권자의 동의 없이 콘텐츠를 배포 및 가공하는 행위가 적발될 경우, 저작권 침해에 관한 법적인 제재를 받을 수 있습니다.'
                    ]
                  }
                ]
              },
              {
                subtitle: '기타 안내',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '현장 중계 사정에 따라 러닝타임 및 프로그램이 변경될 수 있습니다.',
                      '본 공연은 다시 보기 VOD 서비스로 추후 발매될 예정입니다.',
                      '콘서트 관련 문의는 FAQ 혹은 고객센터 1:1 문의를 이용해주시기 바랍니다.'
                    ]
                  }
                ]
              }
            ]
          }
        ],
        guideEntrance: [
          {
            title: '콘서트 시청 안내',
            sub: [
              {
                subtitle: null,
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '스트리밍은 모바일과 PC에서 모두 이용 가능하나, ',
                      '1개의 계정 당 1개의 기기에서 무료 스트리밍할 수 있습니다.',
                      '동시 접속은 불가능하며, 동시 접속 시 기존 기기는 자동 로그아웃됩니다.',
                      '실시간 라이브는 총 5개의 멀티뷰 화면을 제공하며, 1개의 메인 뷰와 4개의 시점 뷰로 이루어집니다.',
                      '콘서트 자막은 한국어 / 영어 / 일어 / 자막 없음 총 4가지로 지원합니다.' ,
                      '해당 콘서트는 UNIVERSE 공식 유튜브 채널에서도 시청하실 수 있습니다.',
                      '단, 유튜브 채널에서는 싱글뷰와 자막 없이 화면이 송출됩니다.'
                    ]
                  }
                ]
              }
            ]
          },
          {
            title: '콘서트 페이지 기능 안내 사항 FAQ',
            sub: [
              {
                subtitle: 'Q. 동시에 여러 개의 기기로 공연을 볼 수 있나요?',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '1 계정당 1 기기에서만 공연을 시청할 수 있습니다.  다른 기기 동시 접속 시 자동 로그아웃됩니다.',
                    ]
                  }
                ]
              },
              {
                subtitle: 'Q. 뷰 선택은 어떻게 되나요?',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '기본적으로 1개의 메인 화면과 4개의 멀티뷰로 총 5개의 화면을 제공합니다. 상단 화면 바에 5개 뷰 화면 중 보고 싶은 화면을 체크를 하시면 원하는 화면이 분할로 제공이 됩니다. 1개~5개로 복수 선택이 가능하며, 그에 맞게 화면 분할이 됩니다. 다만 iOS는 전체화면 멀티뷰가 제공되지 않습니다.'
                    ]
                  }
                ]
              },
              {
                subtitle: 'Q. 큰 화면에서 공연을 시청하고 싶은데 어떻게 해야 하나요?',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '사용자의 모바일 및 TV 기능에 따라 미러링을 이용하실 수 있습니다. 자세한 이용법은 보유한 기기의 사용 안내서를 확인해주세요. 만약 보유한 기기에서 미러링이 지원되지 않는 경우, 컴퓨터나 모바일 기기를 HDMI를 통해 TV나 모니터에 연결하여 큰 화면으로 공연을 즐길 수 있습니다.'
                    ]
                  }
                ]
              }
            ]
          },
          {
            title: '주의 사항',
            sub: [
              {
                subtitle: '법적 고지',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '콘텐츠를 무단 배포 및 가공하는 행위는 저작권 침해에 해당합니다. 콘서트를 임의로 녹화하여 추출하는 행위, 다수의 시청이 가능한 곳에서 중계하는 행위 등은 엄격히 금지하고 있습니다.',
                      '상기 불법행위 혹은 저작권자의 동의 없이 콘텐츠를 배포 및 가공하는 행위가 적발될 경우, 저작권 침해에 관한 법적인 제재를 받을 수 있습니다.'
                    ]
                  }
                ]
              },
              {
                subtitle: '기타 안내',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '현장 중계 사정에 따라 러닝타임 및 프로그램이 변경될 수 있습니다.',
                      '콘서트 관련 문의는 FAQ 혹은 고객센터 1:1 문의를 이용해주시기 바랍니다.'
                    ]
                  }
                ]
              }
            ]
          }
        ],

        planB: {
          button: '콘서트 입장'
        },

        avoidIE : [
          'IE, 구형 Edge, Firefox 는 사용하실 수 없습니다.',
          'Chrome, 신형 Edge, Safari 를 사용해주세요.'
        ],

        closing: {
          sentence: [
            '콘서트가 종료되었습니다.',
            '많은 성원에 감사드립니다.'
          ]
        },

        installation: {
          tag: 'UNIVERSE 앱을 설치하고, 자유롭게 즐기세요.',
          h1: '콘서트 시작',
          t1: '2021년 2월 14일 19:00시',
          h2: '앱으로 즐기는 방법',
          t2: 'UNIVERSE 앱을 설치하고, 회원가입 후 로그인',
          h3: '웹으로 즐기는 방법',
          t3: '앱에서 OTP 발급받고, 웹에 입력 후 감상'
        },

        standby: {
          invitation: [
            {type:'emphasis', text: 'UNI-KON'},
            {type:'normal', text: '에 초대합니다.'}
          ]
        },

        otp: {
          title: 'OTP를 입력해주세요.',
          info1: 'UNIVERSE에서 발급 받은 OTP 번호를 입력해주세요.',
          info2: [
            'OTP 번호는 계정 당 1개만 유효합니다.',
            '발급된 OTP 번호는 1회만 사용 가능합니다.'
          ],
          button: '입장하기',
          invalid: '유효하지 않은 OTP입니다.'
        }
      },
      en: {
        service: {
          name: 'Terms of Service',
          url: 'https://policy.universe-official.io/policy/service/universe/ko'
        },
        privacy: {
          name: 'Privacy Policy',
          url: 'https://policy.universe-official.io/policy/privacy/universe/ko'
        },
        cs: {
          name: 'Contact Us',
          url: 'https://cs.universe-official.io?locale=en_US'
        },
        biz: {
          name: 'Partnership',
          url: 'universe_biz@ncsoft.com'
        },
        infoOpenAt: 'Site Open',
        infoEventAt: 'Concert Start',
        modal: {
          duplicate: 'A connection was found on a new device.',
          offline: 'The network is unstable. Please check the network status.'
        },
        guidePrologue: [
          {
            title: 'Concert Notice',
            sub: [
              {
                subtitle: 'Concert Schedule',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '2021, Feb 14th (Sun) 19:00 (KST)'
                    ]
                  }
                ]
              },
              {
                subtitle: 'Viewing Guidelines',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'Streaming is available on both mobile and PC, but each user may only stream for free on one device per account.',
                      'Simultaneous logins are not allowed and existing logged in sessions will be automatically logged out when a concurrent connection is made.',
                      'The live concert offers a total of five multi-view screens, consisting of one main screen and four additional angles.',
                      'The concert will provide 4 subtitle options: Korean / English / Japanese / No subtitles',
                      'You can get issued an OTP and gain access to the concert page starting from 17:50, Feb 14th.',
                      'Please check your network connection in advance.',
                      'UNI-KON can be watched at UNIVERSE\'s official YouTube channel, while the concert will be viewed in a single view cam without subtitles.'
                    ]
                  }
                ]
              },
              {
                subtitle: 'How to Enter the Concert Page',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'Step 1. Log into the UNIVERSE app',
                      'Step 2. Enter UNIVERSE Home and click on the concert banner',
                      'Step 3. Receive an OTP',
                      'Step 4. Move to platform to watch (PC Web (<<link>>https://uni-kon.universe-official.io<</link>>) /Mobile Web)',
                      'Step 5. Input OTP',
                      'Step 6. Enter concert page and enjoy'
                    ]
                  }
                ]
              },
              {
                subtitle: 'Device Requirement',
                numbering: [
                  {
                    numberTitle: '1. Check Device',
                    body: [
                      'PC OS: Mac OS 10.12+ / Window 7+',
                      'Mobile OS: iOS 11+ / Android 8 +',
                      'PC browser: Chrome, Edge, Safari'
                    ]
                  },
                  {
                    numberTitle: '2. Settings Configuration',
                    body: [
                      'Please connect to a network of 20 Mbps or higher for a smooth viewing experience. Public networks may require more than 20 Mbps for the same viewing experience.',
                      'Data usage may increase with 3G/LTE',
                      'Image quality will be adjusted automatically.',
                      'Please keep in mind that sound quality may be affected by comms/data and other external factors.'
                    ]
                  }
                ]
              }
            ]
          },
          {
            title: 'FAQ',
            sub: [
              {
                subtitle: 'Q. Where can I watch UNI-KON?',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'Download the UNIVERSE app and log in, and click on the UNI-KON banner to go to the concert page. Once there, you can authenticate your OTP and start watching on your PC or mobile device.'
                    ]
                  }
                ]
              },
              {
                subtitle: 'Q. How do you watch UNI-KON on mobile?',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '1. Download the UNIVERSE app and log in',
                      '2. Enter UNIVERSE Home and click on the concert banner',
                      '3. After you receive an OTP, click on the mobile website',
                      '4. Input OTP',
                      '5. Enjoy the performance once live streaming begins'
                    ]
                  }
                ]
              },
              {
                subtitle: 'Q. How do I watch UNI-KON on PC?',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '1. Download the UNIVERSE app and log in',
                      '2. Enter UNIVERSE Home and click on the concert banner',
                      '3. Issue an OTP and click on the PC website (<<link>>https://uni-kon.universe-official.io<</link>>)',
                      '4. Input OTP on the PC website and enjoy the concert.'
                    ]
                  }
                ]
              },
              {
                subtitle: 'Q. I logged in on the UNIVERSE app and clicked on the banner, but nothing happens.',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'You can enter the concert page one hour prior to the concert at the earliest. Click on the activated OTP issue button during this time.'
                    ]
                  }
                ]
              },
              {
                subtitle: 'Q. What internet browsers can I use to watch the concert?',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'Chrome, Edge, and Safari are supported on PC. IE is not supported.'
                    ]
                  }
                ]
              },
              {
                subtitle: 'Q. How can I watch the performance on a larger external screen?',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'Users can utilize the mirroring function based on the functions supported on your TV and mobile device. Please check your device instructions for more info.',
                      'If mirroring is not supported on your device, you can connect your computer or mobile device to a TV via HDMI and enjoy the performance on a bigger screen.'
                    ]
                  }
                ]
              },
              {
                subtitle: 'Q. May I watch the performance on multiple devices simultaneously?',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'Each user may only watch from one device. You will be logged out automatically if you connect from a secondary device.',
                    ]
                  }
                ]
              },
              {
                subtitle: 'Q. Can I see the performance again after the live concert ends?',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'This performance will be released later as a replay VOD service.',
                    ]
                  }
                ]
              }
            ]
          },
          {
            title: 'Pre-opening - Announcements',
            sub: [
              {
                subtitle: 'Legal Disclaimer',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'Unauthorized distribution and processing of content constitutes copyright infringement. The act of recording and extracting a concert and broadcasting it in a place where a large numbers of viewers are present is strictly prohibited.',
                      'Individuals caught doing so may be subject to legal sanctions based on copyright infringement.'
                    ]
                  }
                ]
              },
              {
                subtitle: 'Others',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'Runtimes and programs may change depending on the on-site broadcasting circumstances',
                      'This performance will be released later as a replay VOD service',
                      'Please use the FAQ or Customer Center 1:1 inquiry service for concert-related inquiries.'
                    ]
                  }
                ]
              }
            ]
          }
        ],
        guideStandby: [
          {
            title: 'UNI-KON Admission Instructions and Precautions',
            sub: [
              {
                subtitle: 'Viewing Guidelines',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'Streaming is available on both mobile and PC, but each user may only stream for free on one device per account.',
                      'Simultaneous logins are not allowed and existing logged in sessions will be automatically logged out when a concurrent connection is made.',
                      'The live concert offers a total of five multi-view screens, consisting of one main screen and four additional angles.',
                      'The concert will provide 4 subtitle options: Korean / English / Japanese / No subtitles',
                      'You can get issued an OTP and gain access to the concert page starting from 17:50, Feb 14th.',
                      'Please check your network connection in advance.',
                      'UNI-KON can be watched at UNIVERSE\'s official YouTube channel, while the concert will be viewed in a single view cam without subtitles.'
                    ]
                  }
                ]
              },
              {
                subtitle: 'Device Requirement',
                numbering: [
                  {
                    numberTitle: '1. Check Device',
                    body: [
                      'PC OS: Mac OS 10.12+ / Window 7+',
                      'Mobile OS: iOS 11+ / Android 8 +',
                      'PC browser: Chrome, Edge, Safari'
                    ]
                  },
                  {
                    numberTitle: '2. Settings Configuration',
                    body: [
                      'Please connect to a network of 20 Mbps or higher for a smooth viewing experience. Public networks may require more than 20 Mbps for the same viewing experience.',
                      'Data usage may increase with 3G/LTE',
                      'Image quality will be adjusted automatically.',
                      'Please keep in mind that sound quality may be affected by comms/data and other external factors.'
                    ]
                  }
                ]
              },
              {
                subtitle: 'How to Enter the Concert Page',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'Step 1. Log into the UNIVERSE app',
                      'Step 2. Enter UNIVERSE Home and click on the concert banner',
                      'Step 3. Receive an OTP',
                      'Step 4. Move to platform to watch (PC Web (<<link>>https://uni-kon.universe-official.io<</link>>) /Mobile Web)',
                      'Step 5. Input OTP',
                      'Step 6. Enter concert page and enjoy'
                    ]
                  }
                ]
              },
              {
                subtitle: 'Legal Disclaimer',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'Unauthorized distribution and processing of content constitutes copyright infringement. The act of recording and extracting a concert and broadcasting it in a place where a large numbers of viewers are present is strictly prohibited.',
                      'Individuals caught doing so may be subject to legal sanctions based on copyright infringement.'
                    ]
                  }
                ]
              },
              {
                subtitle: 'Others',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'Runtimes and programs may change depending on the on-site broadcasting circumstances',
                      'This performance will be released later as a replay VOD service',
                      'Please use the FAQ or Customer Center 1:1 inquiry service for concert-related inquiries.'
                    ]
                  }
                ]
              }
            ]
          }
        ],
        guideEntrance: [
          {
            title: 'Concert Viewing Guidelines',
            sub: [
              {
                subtitle: null,
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'Streaming is available on both mobile and PC, but each user may only stream for free on one device per account.',
                      'Simultaneous logins are not allowed and existing logged in sessions will be automatically logged out when a concurrent connection is made.',
                      'The live concert offers a total of five multi-view screens, consisting of one main screen and four additional angles.',
                      'The concert will provide 4 subtitle options: Korean / English / Japanese / No subtitles',
                      'UNI-KON can be watched at UNIVERSE\'s official YouTube channel, while the concert will be viewed in a single view cam without subtitles.'
                    ]
                  }
                ]
              },
            ]
          },
          {
            title: 'Concert View Page Functional Notes FAQ',
            sub: [
              {
                subtitle: 'Q. May I watch the performance on multiple devices simultaneously?',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'Each user may only watch from one device. You will be logged out automatically if you connect from a secondary device.',
                    ]
                  }
                ]
              },
              {
                subtitle: 'Q. How do I select a view?',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'A. We provide a total of 5 screens, including 1 main screen with 4 additional multi-views. Once you select the view you would like to see from the top of the screen, your selection will be shown on screen.A total of 5 multi-view screens can be chosen and your screen will be split accordingly. However, full-screen multi-view is not available for iOS.'
                    ]
                  }
                ]
              },
              {
                subtitle: 'Q. How can I watch the performance on a larger external screen?',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'Users can utilize the mirroring function based on the functions supported on your TV and mobile device. Please check your device instructions for more info. If mirroring is not supported on your device, you can connect your computer or mobile device to a TV via HDMI and enjoy the performance on a bigger screen.'
                    ]
                  }
                ]
              }
            ]
          },
          {
            title: 'Admission – Precaution',
            sub: [
              {
                subtitle: 'Legal Disclaimer',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'Unauthorized distribution and processing of content constitutes copyright infringement. The act of recording and extracting a concert and broadcasting it in a place where a large numbers of viewers are present is strictly prohibited.',
                      'Individuals caught doing so may be subject to legal sanctions based on copyright infringement.'
                    ]
                  }
                ]
              },
              {
                subtitle: 'Others',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'Runtimes and programs may change depending on the on-site broadcasting circumstances',
                      'Please use the FAQ or Customer Center 1:1 inquiry service for concert-related inquiries.'
                    ]
                  }
                ]
              }
            ]
          }
        ],

        planB: {
          button: 'Enter the concert'
        },

        avoidIE : [
          'IE, old Edge, Firefox are not available.',
          'Please use Chrome, new Edge, Safari.'
        ],

        closing: {
          sentence: [
            'The concert has ended.',
            'Thank you for your support.'
          ]
        },

        installation: {
          tag: 'Install the UNIVERSE app and enjoy freely with the app and the web.',
          h1: 'Concert Start',
          t1: 'February 14, 2021 at 19:00 (KST)',
          h2: 'How to enjoy with the app',
          t2: 'Install the UNIVERSE app, sign up and log in',
          h3: 'How to enjoy with the web',
          t3: 'Receive OTP from the app, enter it on the web and watch the concert'
        },

        standby: {
          invitation: [
            {type:'normal', text: 'We invite you to '},
            {type:'emphasis', text: 'UNI-KON'}
          ]
        },

        otp: {
          title: 'Please enter OTP.',
          info1: 'Please enter the OTP number issued by UNIVERSE.',
          info2: [
            'Only one OTP number is valid per account.',
            'The issued OTP number can be used only once.'
          ],
          button: 'Enter',
          invalid: 'Invalid OTP.'
        }
      },
      ja: {
        service: {
          name: '利用規約',
          url: 'https://policy.universe-official.io/policy/service/universe/ja'
        },
        privacy: {
          name: 'プライバシーポリシー',
          url: 'https://policy.universe-official.io/policy/privacy/universe/ja'
        },
        cs: {
          name: 'カスタマーセンター',
          url: 'https://cs.universe-official.io?locale=ja'
        },
        biz: {
          name: '業務提携のご提案',
          url: 'universe_biz@ncsoft.com'
        },
        infoOpenAt: 'サイトオープン',
        infoEventAt: 'コンサート開演',
        modal: {
          duplicate: '新しいデバイスからの接続が発見されました。',
          offline: 'ネットワークが不安定です。 ネットワーク状態を確認してください。'
        },
        guidePrologue: [
          {
            title: 'コンサートのご案内',
            sub: [
              {
                subtitle: '公演日時',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '2021年2月14日(日) 19:00(KST)'
                    ]
                  }
                ]
              },
              {
                subtitle: '視聴のご案内',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'ストリーミングはモバイルとPCのどちらでもご利用いただけますが、1つのアカウントあたり1つのデバイスでのみ無料ストリーミングできます。',
                      '同時接続はできません。同時接続時、既存のデバイスは自動ログアウトされます。',
                      'リアルタイムライブは計5つのマルチビュー画面で構成されており、1つのメインビューと4つの視点ビューで提供されます。',
                      'コンサートの字幕は、韓国語/英語/日本語/字幕なしの全４種でご提供致します。',
                      'コンサートページは、２月14日17時50分からOTP発行を通して接続可能です！',
                      '予めネットワーク接続をご確認ください。',
                      'このコンサートはUNIVERSE公式YouTubeチャンネルでご覧になれます。',
                      'ただし、YouTubeチャンネルからはシングルビューと字幕なしで画面が送られます。',
                    ]
                  }
                ]
              },
              {
                subtitle: 'コンサートページへの入場方法',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'Step 1．ユニバースアプリにログイン',
                      'Step 2．ユニバースホームに入場後、コンサートバナーをクリック',
                      'Step 3．OTPを発行',
                      'Step 4．視聴するプラットフォームへ移動(PCサイト(<<link>>https://uni-kon.universe-official.io<</link>>) /モバイルサイト)',
                      'Step 5．発行されたOTPを入力',
                      'Step 6．コンサートページへ入場およびコンサート視聴'
                    ]
                  }
                ]
              },
              {
                subtitle: '再生サポート環境',
                numbering: [
                  {
                    numberTitle: '1. デバイス確認',
                    body: [
                      'PC OS: Mac OS 10.12+ / Window 7+',
                      'Mobile OS: iOS 11+ / Android 8 +',
                      'PCブラウザ：Chrome, Edge, Safari'
                    ]
                  },
                  {
                    numberTitle: '2. 環境設定',
                    body: [
                      '快適なご視聴のため、インターネットの通信速度は20 Mbps以上の環境で接続してください。共用ネットワークでは20 Mbps以上でも接続が不安定な場合があります。',
                      '3G/LTEをご利用の場合、データ通信量が増加することがあります。',
                      '動画の画質は自動的に調整されます。',
                      '通信/データの状況およびその他の外部要因により、映像や音声に乱れが発生する場合がありますので、ご視聴の際は予めご了承ください。'
                    ]
                  }
                ]
              },
            ]
          },
          {
            title: 'FAQ',
            sub: [
              {
                subtitle: 'Q．UNI-KONはどこで視聴できますか？',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'UNIVERSEアプリをダウンロード後ログインし、UNI-KONバナーをクリックするとコンサートページに移動します。その後、OTP認証をするとモバイルサイト、またはPCサイトで視聴できます。'
                    ]
                  }
                ]
              },
              {
                subtitle: 'Q．UNI-KONのモバイル視聴方法は？',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '1. UNIVERSEアプリダウンロードおよびログイン',
                      '2. UNIVERSEホーム画面でコンサートバナーをクリックすると、コンサートページへ移動',
                      '3. OTP発行後、モバイルサイトで視聴をクリックして移動',
                      '4. 発行されたOTPを入力',
                      '5. ライブストリーミングが始まったら公演を楽しむ'
                    ]
                  }
                ]
              },
              {
                subtitle: 'Q. UNI-KONのPCサイトでの視聴方法は？',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '1. UNIVERSEアプリダウンロードおよびログイン',
                      '2. UNIVERSEホーム画面でコンサートバナーをクリックすると、コンサートページへ移動',
                      '3. OTP発行後、PCサイト(<<link>>https://uni-kon.universe-official.io<</link>>)で視聴をクリックして移動',
                      '4. 発行されたOTPをPCサイトに入力後、コンサートを楽しむ'
                    ]
                  }
                ]
              },
              {
                subtitle: 'UNIVERSEアプリにログイン後、コンサートバナーをクリックしてもコンサートページに移動しません。',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '当公演のコンサートページへの移動および入場は1時間前から可能です。該当時間に合わせてOTP発行ボタンが有効になります。'
                    ]
                  }
                ]
              },
              {
                subtitle: '公演視聴に使用できるデバイス環境には何がありますか？',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'PCをご利用の場合、Chrome、Edge、Safariをサポートします。IEはサポート対象外です。'
                    ]
                  }
                ]
              },
              {
                subtitle: '大画面で公演を視聴する場合は？',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'ユーザーのモバイルおよびTV機能によってミラーリングをご利用いただけます。',
                      '詳しい利用方法はお持ちのデバイスの取扱説明書をご確認ください。',
                      '万が一、お持ちのデバイスがミラーリングをサポートしていない場合、PCやモバイル端末をHDMIを通してTVやモニターと接続すると、大画面で公演をお楽しみいただけます。'
                    ]
                  }
                ]
              },
              {
                subtitle: 'Q. 同時に複数のデバイスで公演を視聴できますか？',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      ' 1つのアカウントあたり1つのデバイスでのみ公演を視聴できます。他のデバイスから同時接続時、自動ログアウトされます。',
                    ]
                  }
                ]
              },
              {
                subtitle: 'Q. ライブ公演終了後、公演を再視聴できますか？',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '当公演は再視聴VODサービスで今後販売される予定です。',
                    ]
                  }
                ]
              }
            ]
          },
          {
            title: 'オープン前 – ご案内',
            sub: [
              {
                subtitle: '法的告知',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'コンテンツを無断配布および加工する行為は著作権侵害に該当します。コンサートを録画して抽出する行為、多数の人が視聴できる場所で中継する行為などは厳しく禁止されています。',
                      '上記の違法行為もしくは著作権者の同意なしに、コンテンツの配布および加工する行為が摘発された場合、著作権侵害に関する法的制裁を受けることがあります。'
                    ]
                  }
                ]
              },
              {
                subtitle: 'その他のご案内',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '現場の中継事情により、公演時間およびプログラムが変更されることがあります。',
                      '当公演は再視聴VODサービスで後日販売される予定です。',
                      'コンサートに関するお問い合わせは、FAQまたはカスタマーセンター1：1のお問い合わせをご利用ください。'
                    ]
                  }
                ]
              }
            ]
          }
        ],
        guideStandby: [
          {
            title: 'UNI-KON観覧に関するご案内および注意事項',
            sub: [
              {
                subtitle: '視聴のご案内',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'ストリーミングはモバイルとPCのどちらでもご利用いただけますが、1つのアカウントあたり1つのデバイスでのみ無料ストリーミングできます。',
                      '同時接続はできません。同時接続時、既存のデバイスは自動ログアウトされます。',
                      'リアルタイムライブは計5つのマルチビュー画面で構成されており、1つのメインビューと4つの視点ビューで提供されます。',
                      'コンサートの字幕は、韓国語/英語/日本語/字幕なしの全４種でご提供致します。',
                      'コンサートページは、２月14日17時50分からOTP発行を通して接続可能です！',
                      '予めネットワーク接続をご確認ください。',
                      'このコンサートはUNIVERSE公式YouTubeチャンネルでご覧になれます。',
                      'ただし、YouTubeチャンネルからはシングルビューと字幕なしで画面が送られます。',
                    ]
                  }
                ]
              },
              {
                subtitle: '再生サポート環境',
                numbering: [
                  {
                    numberTitle: '1. デバイス確認',
                    body: [
                      'PC OS: Mac OS 10.12+ / Window 7+',
                      'Mobile OS: iOS 11+ / Android 8 +',
                      'PCブラウザ：Chrome, Edge, Safari'
                    ]
                  },
                  {
                    numberTitle: '2. 環境設定',
                    body: [
                      '快適なご視聴のため、インターネットの通信速度は20 Mbps以上の環境で接続してください。共用ネットワークでは20 Mbps以上でも接続が不安定な場合があります。',
                      '3G/LTEをご利用の場合、データ通信量が増加することがあります。',
                      '動画の画質は自動的に調整されます。',
                      '通信/データの状況およびその他の外部要因により、映像や音声に乱れが発生する場合がありますので、ご視聴の際は予めご了承ください。'
                    ]
                  }
                ]
              },
              {
                subtitle: 'コンサートページへの入場方法',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'Step 1．ユニバースアプリにログイン',
                      'Step 2．ユニバースホームに入場後、コンサートバナーをクリック',
                      'Step 3．OTPを発行',
                      'Step 4．視聴するプラットフォームへ移動(PCサイト(<<link>>https://uni-kon.universe-official.io<</link>>) /モバイルサイト)',
                      'Step 5．発行されたOTPを入力',
                      'Step 6．コンサートページへ入場およびコンサート視聴'
                    ]
                  }
                ]
              },
              {
                subtitle: '法的告知',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'コンテンツを無断配布および加工する行為は著作権侵害に該当します。コンサートを録画して抽出する行為、多数の人が視聴できる場所で中継する行為などは厳しく禁止されています。',
                      '上記の違法行為もしくは著作権者の同意なしに、コンテンツの配布および加工する行為が摘発された場合、著作権侵害に関する法的制裁を受けることがあります。'
                    ]
                  }
                ]
              },
              {
                subtitle: 'その他のご案内',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '現場の中継事情により、公演時間およびプログラムが変更されることがあります。',
                      'システムが不安定な場合、シングルビューと字幕なしに画面が送出されることがありますので、ご参考お願い致します。',
                      '当公演は再視聴VODサービスで後日販売される予定です。',
                      'コンサートに関するお問い合わせは、FAQまたはカスタマーセンター1：1のお問い合わせをご利用ください。'
                    ]
                  }
                ]
              }
            ]
          }
        ],
        guideEntrance: [
          {
            title: 'コンサートの視聴案内',
            sub: [
              {
                subtitle: null,
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'ストリーミングはモバイルとPCのどちらでもご利用いただけますが、1つのアカウントあたり1つのデバイスでのみ無料ストリーミングできます。',
                      '同時接続はできません。同時接続時、既存のデバイスは自動ログアウトされます。',
                      'リアルタイムライブは計5つのマルチビュー画面で構成されており、1つのメインビューと4つの視点ビューで提供されます。',
                      'コンサートの字幕は、韓国語/英語/日本語/字幕なしの全４種でご提供致します。' ,
                      'このコンサートはUNIVERSE公式YouTubeチャンネルでご覧になれます。',
                      'ただし、YouTubeチャンネルからはシングルビューと字幕なしで画面が送られます。',
                    ]
                  }
                ]
              }
            ]
          },
          {
            title: '入場 – コンサートページ機能のご案内FAQ',
            sub: [
              {
                subtitle: 'Q．同時に複数のデバイスで公演を視聴できますか？',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '1つのアカウントあたり1つのデバイスでのみ公演を視聴できます。他のデバイスから同時接続時、自動ログアウトされます。',
                    ]
                  }
                ]
              },
              {
                subtitle: 'Q．ビューの選択方法は？',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '基本的に1つのメイン画面と4つのマルチビューの計5の画面を提供します。 画面上段バーの5つのビュー画面の中から見たい画面をチェックすると、ご希望の画面が分割で提供されます。 1～5つから複数選択することができ、それに合わせて画面が分割されます。 ただし、iOSの場合はマルチビューが提供されません。'
                    ]
                  }
                ]
              },
              {
                subtitle: 'Q．大画面で公演を視聴する場合は？',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'ユーザーのモバイルおよびTV機能によってミラーリングをご利用いただけます。 詳しい利用方法はお持ちのデバイスの取扱説明書をご確認ください。 万が一、お持ちのデバイスがミラーリングをサポートしていない場合、PCやモバイル端末をHDMIを通してTVやモニターと接続すると、大画面で公演をお楽しみいただけます。'
                    ]
                  }
                ]
              }
            ]
          },
          {
            title: '入場 – 注意事項',
            sub: [
              {
                subtitle: '法的告知',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      'コンテンツを無断配布および加工する行為は著作権侵害に該当します。コンサートを録画して抽出する行為、多数の人が視聴できる場所で中継する行為などは厳しく禁止されています。',
                      '上記の違法行為もしくは著作権者の同意なしに、コンテンツの配布および加工する行為が摘発された場合、著作権侵害に関する法的制裁を受けることがあります。'
                    ]
                  }
                ]
              },
              {
                subtitle: 'その他のご案内',
                numbering: [
                  {
                    numberTitle: null,
                    body: [
                      '現場の中継事情により、公演時間およびプログラムが変更されることがあります。',
                      'その他のコンサートに関するお問い合わせは、FAQまたはカスタマーセンター1：1のお問い合わせをご利用ください。'
                    ]
                  }
                ]
              }
            ]
          }
        ],

        planB: {
          button: 'コンサート入場'
        },

        avoidIE : [
          'IE、旧型Edge、Firefoxはご使用いただけません。',
          'Chrome、新型Edge、Safari をお使いください。'
        ],

        closing: {
          sentence: [
            'コンサートが終了しました。',
            '多くの声援に感謝いたします。'
          ]
        },

        installation: {
          tag: 'UNIVERSEアプリをインストールして、アプリとウェブで自由にお楽しみください。',
          h1: 'コンサートスタート',
          t1: '2021年2月14日19:00 (KST)',
          h2: 'アプリでの楽しみ方',
          t2: 'UNIVERSEアプリをインストールし、会員登録後ログイン',
          h3: 'ウェブでの楽しみ方',
          t3: 'アプリからOTPを取得し、ウェブに入力してコンサートを見ると'
        },

        standby: {
          invitation: [
            {type:'emphasis', text: 'UNI-KON'},
            {type:'normal', text: 'に招待します。'}
          ]
        },

        otp: {
          title: 'OTPを入力してください。',
          info1: 'UNIVERSEで発行されたOTP番号を入力してください。',
          info2: [
            'OTP番号はアカウントあたり1つのみ有効です。',
            '発行されたOTP番号は1回のみ使用可能です。'
          ],
          button: '入場',
          invalid: '無効なOTP です。'
        }
      },
    },
  }

export default config;